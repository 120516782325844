import { styled, Box } from "@mui/material";

export const TicketItem = styled(Box)(({theme}) => ({
  backgroundColor: 'rgba(0,0,0,0)',
  borderRadius: '0',
  textAlign: 'center',
  objectFit: 'contain',
  width: '90%',
  height: 'auto',
  color: theme.palette.text.secondary
}));