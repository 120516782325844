import React, { ReactNode } from "react";
import {motion, MotionStyle} from 'framer-motion';


interface FadeInProps {
  children?: ReactNode,
  delay?: number | 0.
  sx?: MotionStyle
}


export default function FadeInAnimation({children, delay, sx}: FadeInProps): React.JSX.Element {

  return(
    <motion.div
      initial={{opacity: 0}}
      whileInView={{opacity: 1}}
      transition={{duration: .5, delay: delay}}
      viewport={{once: true}}
      style={sx}
    >{children}</motion.div>
  )

}