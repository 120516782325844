import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';


// A custom theme for this app
let theme = createTheme({
  palette: {
    primary: {
      main: '#062e60',
    },
    secondary: {
      main: '#FFFFFF',
    },
    error: {
      main: red.A200,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

export default theme;
