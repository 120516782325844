import React from 'react';
import { Grid2 as Grid, Link } from "@mui/material";
import logo from '../assets/images/logo-1.png';

export default function Logo({isMobile}): React.JSX.Element{
  
  return (
    <Grid sx={{ zIndex: 3}}>
      <Link href='/'>
        <img src={logo} style={{width: '10cqw', height: '8cqw', margin: 'calc(2vw + 2vh)', position: 'absolute' }}/>
      </Link>
    </Grid>
  )
}