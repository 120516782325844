import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';
import FadeInAnimation from './FadeIn';

interface HorizontalTicketProps {
  id?: string, 
  content: string,
  animationDelay?: number
}


export default function HorizontalTicket({id, content, animationDelay}: HorizontalTicketProps): React.JSX.Element{
  const theme = useTheme();

  return (
    <Box id={id} sx={{width: '75%', height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Box sx={{width: '100%', height: '80%', position: 'absolute'}}>
        <svg id="w0L4ZviQoOTjIMKa" viewBox="0 0 146.064 35.3137" width='100%' height='100%'><g id="j64aBGLR8uW5sZsQ"><path id="rXu4bIKLobtytIY4" d="M139.81063067,0 L6.25338984,0 C6.25338984,3.44086003 3.4707799,6.25338984 0,6.25338984 L0,29.06026971 C3.44086003,29.06026971 6.25338984,31.84287011 6.25338984,35.31366861 L139.81063067,35.31366861 C139.81063067,31.87276971 142.59323107,29.06026971 146.06402956,29.06026971 L146.06402956,6.25338984 C142.62313067,6.25338984 139.81063067,3.4707799 139.81063067,0 Z" fill='#f8f5ef'></path></g></svg>
      </Box>
      <FadeInAnimation delay={animationDelay} sx={{width: '100%', height: '50%', position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '0', padding: '0'}}>
        <Box sx={{width: '100%', height: '100%', position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{width: '60%', position: 'absolute', fontSize: '1.8cqw', fontWeight: 'bold', lineHeight: '1.2', textWrap: 'wrap', color: theme.palette.primary.main }} textAlign='center'>{content}</Typography>
        </Box>
      </FadeInAnimation>
    </Box>
  )
}