import React from 'react';
import Logo from './Logo';
import NavItems from './NavItems';
import {Grid2 as Grid }from '@mui/material';

interface NavProps {
  activeRoute: string,
  isMobile: boolean
}

export default function Nav({activeRoute, isMobile}: NavProps): React.JSX.Element {
  return (
    <>
      <Grid container sx={{display: 'flex', justifyContent: 'flex-start', position: 'absolute'}}>
        <Logo isMobile={isMobile}/>
      </Grid>
      <Grid container spacing={0} sx={{display: 'flex', justifyContent: 'flex-end', width: '100cqw'}}>
        <NavItems activeRoute={activeRoute} isMobile={isMobile} />
      </Grid>
    </>
  )
}