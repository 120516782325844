import { Box } from "@mui/material";
import React from "react";
import isLoadingAnim from '../assets/animations/Satokie-isLoading.lottie';
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export default function IsLoading(): React.JSX.Element {
  
  
  return (
    <Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Box sx={{width: '50%', height: '50%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
        <DotLottieReact
          autoplay
          loop
          src={isLoadingAnim}
        />
      </Box>
    </Box>
  )
}