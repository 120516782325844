import React from 'react';
import {Box, Link, Typography, useTheme} from '@mui/material';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Section from './Section';
import poloImg from '../assets/images/polo.jpg';
import terralinesSvg from '../assets/images/terralines.svg';

interface FProps {
  isMobile: boolean
}

export default function Footer({isMobile}: FProps): React.JSX.Element {
  const theme = useTheme();

  
  return (
    <React.Fragment> 
      <Section id='footer-contact' elevation={0} height={isMobile ? 20 : 90} sx={{backgroundColor: theme.palette.primary.main,  overflow: 'hidden'}}>
      <Box id='footer-img-container' sx={{width: '100%', height: '80%', position: 'relative'}}>
        <Box id='background-img' sx={{width: '100%', height: '100%', position: 'absolute'}}>
          <img src={poloImg} style={{objectFit: 'cover', height: '100%', width: '100%', aspectRatio: '13/5'}} alt="Satokie Logo on Polo Shirt" />
        </Box>
      </Box>
      <Box id='subfooter-container' sx={{height: '20%', width: '100%', position: 'relative'}}>
        <Box id='subfooter-background-img' sx={{height: '100%', width: '100%', position: 'absolute'}}>
            <img src={terralinesSvg} style={{width: '100%', height: '200%', objectFit: 'cover', aspectRatio: '16/9'}} alt="terralines background svg" />
        </Box>
        <Box id='subfooter-overlay' sx={{height: '100%', width: '90%', marginLeft: '3cqw', position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <Box sx={{textAlign: 'center'}}>
            <Link href="mailto:contact@satokiemining.io">
              <Typography variant='h6' sx={{ fontSize: '1.5cqw', color: theme.palette.secondary.main, textShadow: '0em .4em .5em rgba(0,0,0, .8) !important', textDecoration: 'underline'}}>contact@satokiemining.io</Typography>
            </Link>
          </Box>
          <Box>
            <Link href="https://x.com/SatokieMining">
              <XIcon sx={{ fontSize: '1.9cqw', marginTop: 'calc(.65vw + .65vh)', marginRight: '.5cqw', marginLeft: '1.5rem', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}}/>
            </Link>
            <Link href="https://www.linkedin.com/company/satokie-mining">
              <LinkedInIcon sx={{ fontSize: '2cqw', marginTop: '1cqh', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}} />
            </Link> 
          </Box>
        </Box>
      </Box>
    </Section>
  </React.Fragment>
)
}