import React, {useEffect, useRef, useState} from 'react';
import IsLoading from './isLoading';
import { CSSObject, Box} from '@mui/material';

interface VideoItemProps {
  sx?: CSSObject,
  videoUrl: string,
  isMobile?: boolean,
  typeString: string
}

export default function VideoItem ({sx, videoUrl, isMobile, typeString}) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  
  const handleLoadedMetadata = () => {
    setIsLoading(false);
  }


  return (
    <React.Fragment>
      {isLoading && <IsLoading />}
        <video
          ref={videoRef}
          style={sx} 
          autoPlay 
          loop 
          muted
          playsInline
          preload='auto'
          onLoadedMetadata={handleLoadedMetadata}
        >
          <source src={videoUrl} type={typeString} />
          Your browser does not support this video tag. 
        </video>
    </React.Fragment>
  )
}