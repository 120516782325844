import React, { ReactNode } from "react";
import {motion, MotionStyle} from 'framer-motion';

interface FadeInUpProps {
  children?: ReactNode,
  delay?: number | 0,
  sx?: MotionStyle
}


export default function FadeInUpAnimation({children, delay, sx}: FadeInUpProps): React.JSX.Element {

  return(
    <motion.div
      initial={{y: 50, opacity: 0}}
      whileInView={{y: 0, opacity: 1}}
      transition={{duration: .5, delay: delay}}
      viewport={{once: true}}
      style={sx}
    >{children}</motion.div>
  )

}