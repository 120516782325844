import React from 'react';
import { Grid2 as Grid, Link, Typography, useTheme } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

interface NIProps {
  isMobile: boolean,
  activeRoute: string
}


export default function NavItems({isMobile, activeRoute}: NIProps): React.JSX.Element {
  const theme = useTheme();
  const navLinkStyle = {
    color: theme.palette.secondary.main, 
    textWrap: 'nowrap'
  };
  
  
  const XIconStyle = {
    fontSize: 'calc(1vw + 1vh)', marginTop: 'calc(.65vw + .65vh)', marginRight: 'calc(4vw+4vh)', marginLeft: '1.5rem', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'
  }
  
  const LinkedInIconStyle = {
    fontSize: 'calc(1.2vw + 1.2vh)', marginTop: 'calc(.55vw + .55vh)', marginRight: 'calc(.4vw+ .4vh)', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'
  }
  
  const navItems = [
    // {id: 0, ref: '/about', text: 'About', isActive: false},
    {id: 1, ref: '/services', text: 'Services', isActive: false},
    {id: 2, ref: '/midstream', text:'Satokie Midstream', isActive: false},
    // {id: 3, ref: '/faq', text: 'FAQ', isActive: false},
  ]; 

  const handleScrollToContact = () => {
    const footerElement = document.getElementById('footer-contact');
    if(footerElement){
      footerElement.scrollIntoView({behavior: 'smooth'})
    }
  }
  
  
  return (
    <Grid size={6} sx={{ display: 'flex', justifyContent:' flex-end', position: 'absolute', zIndex: 3, margin: isMobile ? '0': '2cqw', marginTop: isMobile ? '5.5cqw': '2cqw', marginRight: isMobile ? '4cqw': '2cqw'}}>
      {navItems.map( item => (
        <Link key={item.id} href={item.ref} sx={navLinkStyle} style={{marginTop: isMobile? '1cqw': '0', textUnderlineOffset: '.5rem', textDecorationColor: 'rgba(255,255,255,.5)'}} underline='hover'>
          <Typography sx={{fontSize: isMobile ? '2cqw': 'calc(1vw + 1vh)', margin: isMobile ? '.8cqw': 'calc(.5vw + .5vh)', textShadow: '0em .2em .5em rgba(0,0,0,.9) !important' ,textDecoration: activeRoute === item.ref ? 'underline': 'none', textUnderlineOffset: '.5rem'}}>{item.text}</Typography>
        </Link>
      ))}
      <Link 
        onClick={handleScrollToContact}
        sx={navLinkStyle} 
        style={{marginTop: isMobile? '1cqw': '0', textUnderlineOffset: '.5rem', textDecorationColor: 'rgba(255,255,255,.5)'}}
        underline='hover'>
        <Typography sx={{fontSize: isMobile ? '2cqw': 'calc(1vw + 1vh)', margin: isMobile ? '.8cqw': 'calc(.5vw + .5vh)', textShadow: '0em .2em .5em rgba(0,0,0,.9) !important', cursor: 'pointer'}}>Contact</Typography>
      </Link>
      <Link href="https://x.com/SatokieMining" rel='noopener'>
        <XIcon sx={isMobile ? {fontSize: '3cqw', marginTop: '1.5cqw', marginRight: '.3cqw', marginLeft: '2cqw', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'} : XIconStyle}/>
      </Link>
      <Link href="https://www.linkedin.com/company/satokie-mining" rel='noopener'>
        <LinkedInIcon sx={isMobile ? { fontSize: '3.8cqw', marginTop: '1.2cqw', marginRight: '.1cqw', color: theme.palette.secondary.main, textDecoration: 'none', textWrap: 'nowrap'}: LinkedInIconStyle} />
      </Link>
    </Grid>
  )
}